<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-8">
        <h3 class="text-center">ترتيب الاعمدة</h3>
        <draggable class="list-group" tag="ul" v-model="list" v-bind="dragOptions" @start="drag = true" @end="drag = false">
          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <li class="list-group-item" v-for="element in list" :key="element.order">
              <div class="d-flex justify-content-between">
                <div>
                  <i class="fa-solid fa-bars" aria-hidden="true" style="cursor: move; margin-left: 10px"></i>
                  <i :class="element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'" @click="element.fixed = !element.fixed" aria-hidden="true"></i>
                  {{ element.nameAr }}
                </div>
                <b-form-checkbox v-model="element.isVisible" class="ml-2"></b-form-checkbox>
              </div>
            </li>
          </transition-group>
        </draggable>
      </div>
    </div>

    <div class="row justify-content-center">
      <button class="btn btn-success button mr-1" @click="AddFullReportColumn">حغظ</button>
      <button class="btn btn-secondary button mr-1" @click="sort">التريب السابق</button>
      <button class="btn btn-danger button" @click="cancel">الغاء</button>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { BFormCheckbox } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const message = ['vue.draggable', 'draggable', 'component', 'for', 'vue.js 2.0', 'based', 'on', 'Sortablejs']

export default {
  components: {
    draggable,
    BFormCheckbox
  },

  data() {
    return {
      //   list: message.map((name, index) => {
      //     return { name, order: index + 1 }
      //   }),
      list: [],
      drag: false
    }
  },
  async mounted() {
    await this.GetFullReportColumns()
  },
  methods: {
    async GetFullReportColumns() {
      const response = await this.$store.dispatch('GetFullReportColumns')
      if (response.status === 200) {
        this.list = response.data.map(({ id, ...rest }) => rest)
      }
    },
    async AddFullReportColumn() {
      const payload = this.list.map((item, index) => {
        return { ...item, order: index + 1 }
      })
      const response = await this.$store.dispatch('AddFullReportColumns', payload)
      if (response.status === 200) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'success',
            message: 'Full report columns updated successfully.'
          }
        })
        this.$router.push({ path: '/full-report' })
      }
    },

    cancel() {
      this.$router.push({ path: '/full-report' })
    },

    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order)
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  }
}
</script>

<style>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
